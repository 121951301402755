import IndexedDBHelper from '@/assets/js/IndexedDB'

export default class Configs {
	constructor() {
		this.version = '';
		this.dbase_name = '';
		this.db = new IndexedDBHelper("my-eco-db", 1); //the database helper instance
	}

	async apiUrl(seg='api/') {
		let hostname = window.location.hostname;
		let protocol = window.location.protocol;
		/*if(hostname=="localhost")
		{
			hostname = "eco.obtir.com";
			protocol = "https";
		}*/
		
		let host = window.location.protocol+"//"+hostname;
		console.log("host: ");
		console.log(host);

		let api_url;
		
		let language = await this.db.getStorageItem("language");
		let data_format = await this.db.getStorageItem("data_format");
		let env = await this.db.getStorageItem("env");
		let version = await this.db.getStorageItem("version");
		
		if(language==null || language=="" || language===undefined)
		{
			language = "en";
		}
		
		if(data_format==null || data_format=="" || language===undefined)
		{
			data_format = "json";
		}
		
		if(env==null || env=="" || env===undefined)
		{
			env = "live";
		}
		
		if(version==null || version=="" || version===undefined)
		{
			version = "v1";
		}
		
		api_url = host+'/'+seg+''+version+'/'+language+'/'+data_format+'/'+env;
		
		return api_url;
	}
	
	menuState(m_status=''){
		let output;
		let side_bar;
		let main_area;
		let side_bar_style;
		let menu_status;
		
		if(m_status==null || m_status===undefined || m_status=='')
		{
			let x_menu_status = localStorage.getItem('menu_status');
			
			if(x_menu_status==null || x_menu_status===undefined || x_menu_status=='')
			{
				menu_status = 'open';
			}
			else
			{
				menu_status = x_menu_status;
			}
		}
		else
		{
			menu_status = m_status;
		}
		
		localStorage.setItem('menu_status', menu_status);
		
		return menu_status;
	}
	
	menuStyles(m_status=''){
		let menu_status;
		let output;
		let side_bar;
		let main_area;
		let side_bar_style;
		let bg_color = '#1976d2';
		
		var window_height = window.innerHeight;
		var window_width = window.innerWidth;
		
		
		if(m_status==null || m_status===undefined || m_status=='')
		{
			let x_menu_status = localStorage.getItem('menu_status');
			
			if(x_menu_status==null || x_menu_status===undefined || x_menu_status=='')
			{
				menu_status = 'open';
			}
			else
			{
				menu_status = x_menu_status;
			}
		}
		else
		{
			menu_status = m_status;
		}
		
		if(menu_status=='open')
		{
			side_bar = 'col s12 m4 l3 xl2 show-on-medium-and-up hide-on-small-only';
			main_area = 'col s12 m8 l9 xl10 right';
			side_bar_style = 'position:fixed; top:60px; z-index:10; background-color: '+bg_color+'; border-right: 0.1pt solid #000; border-bottom: 0.1pt solid #000; height:'+window_height+'px';
		}
		else
		{
			side_bar = 'col s12 m1 l1 xl1 show-on-medium-and-up hide-on-small-only';
			main_area = 'col s12 m11 l11 xl11 right';
			side_bar_style = 'position:fixed; top:60px; z-index:10; background-color: '+bg_color+'; border-right: 0.1pt solid #000; border-bottom: 0.1pt solid #000; max-width:80px; height:'+window_height+'px';
		}
		
		output = {
				"menu_status": this.menuState(menu_status),
				"side_bar":side_bar,
				"main_area":main_area,
				"main_area_height":window_height,
				"main_area_width":window_width,
				"side_bar_style":side_bar_style,
				"bg_color":bg_color,
			};
		
		return output;
	}
}